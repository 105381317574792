@import '../../../../scss/theme-bootstrap';

.header-offers-banner-formatter {
  display: block;
  height: 100%;
  min-height: 42px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  @media #{$large-up} {
    height: 56px;
    min-height: 56px;
  }
  &__carousel {
    display: flex;
    justify-content: #{$ldirection};
    margin: 0 auto;
    padding: 0;
    position: relative;
    transform: none;
    top: 50%;
    width: 100%;
    @media #{$large-up} {
      transform: translateY(-50%);
    }
    &-item {
      display: none;
      .not-initialized &:first-child,
      &.item-active {
        display: block;
      }
      .fade & {
        animation-name: fade;
        animation-duration: 1.5s;
      }
    }
  }
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
